<template>
<div>
    <v-autocomplete ref="autoTeam" v-model="listSelected" :items="listItem" :autofocus="fields.autofocus"
        @input="autoImput" :search-input.sync="search" @change="$emit('change')"
        :item-text="textToshow" :item-value="fields.index" :label="title" 
        :style="(fields.zindex) ? 'z-index:' + fields.zindex + ';' : ''" :class="fields.class"
        :multiple="multiSelect" :prepend-icon="preprendIcon" chips small-chips clearable hide-selected 
        :auto-select-first= "fields.autoselectfirst" :disabled="isDisabled" :background-color="this.fields['background-color']"
        :outlined="fields.outlined" :dense="fields.dense" :rounded="fields.rounded" :deletable-chips="fields.deletablechips">
        <template v-slot:no-data>
            <span v-if="notfoundClick" @click="notfoundClick" class="ma-2">{{fields.notfound}} --</span>
            <span v-else class="ma-2">{{fields.notfound}}</span>
        </template>
    </v-autocomplete>
</div>
</template>

<script>

export default {
    name: 'autocomplete_ressource',
    props: ['ressource', 'listParent', 'fields', 'title', 'listObject', 'disabled', 'notfoundClick'], 
    data: function () {
        return {
            loading     : { getVersion: false, getRessource: false },
            ischange    : false,
            search      : null,
            listSelected: null,
            listItem    : [],
            saveRessource : false,
            modalRessource: false,
            multiSelect   : true,
            preprendIcon  : 'mdi-account',
            params        : '',
            isDisabled    : this.disabled || false,
        }
    },
    watch: {
        search (val) {
            if( this.loading.getRessource ){
                clearTimeout(this.loading.getRessource)
            }
            if( this.ressource && val && val.length > 1 ){
                var searchTab = this.fields.search.split(',')
                var search = ''
                searchTab.forEach( (elem) => {
                    search += '&' + elem.trim() + '=%' + val + '%'
                })
                search = '&' + searchTab[0].trim() + '=%' + val + '%' //Todoo multi -> delete this line
                this.loading.getRessource = setTimeout( () => {
                    this.getRessource(search)

                }, 800)
            } else {
                return false
            }
        },
        listObject() {
            this.listSelected = this.listObject
        }
    },
    beforeMount: function(){
        this.fields.outlined           = this.fields.outlined || false
        this.fields.dense              = this.fields.dense || false
        this.fields.filled             = this.fields.filled || false
        this.fields.flat               = this.fields.flat || false
        this.fields['full-width']      = this.fields['full-width'] || false
        this.fields.reverse            = this.fields.reverse || false
        this.fields.rounded            = this.fields.rounded || false
        this.fields['background-color']= this.fields['background-color'] || undefined
        this.fields.deletablechips     = this.fields.deletablechips || false
        

        this.fields.autofocus       = this.fields.autofocus || false
        this.fields.autoselectfirst = this.fields.autoselectfirst || false
        this.fields.params          = this.fields.params || ''
        this.fields.notfound        = this.fields.notfound || 'Aucun élément de trouvé'
        this.fields.prefetch        = this.fields.prefetch || false
        this.fields.resultlimit     = this.fields.resultlimit || 25
        this.params                 = ( this.fields.params.length > 0 ) ? '&' + this.fields.params : '';
    },
    mounted:function (){
        this.multiSelect  = (this.fields.multiSelect == undefined) ? true : this.fields.multiSelect
        this.preprendIcon = (this.fields.preprendIcon == undefined) ? undefined : this.fields.preprendIcon
        this.outlined     = (this.fields.outlined === true) ? true : false
        this.dense        = (this.fields.dense === true) ? true : false
        if( this.multiSelect && this.listObject !== undefined && this.listObject.length > 0 ){
            this.listSelected = this.listObject
        } else if( !this.multiSelect && this.listObject !== undefined ){
            this.listSelected = this.listObject
        }
        if( this.fields.prefetch ){
            this.getRessource('').then( () => {
                this.listItem = this.mergeArrayObject(this.listItem, this.listParent, this.fields.index)
            })
        } else {
            this.listItem     = this.listParent
        }
    },
    methods: {
        getRessource(search){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/' + this.ressource + '/?per_page=' + this.fields.resultlimit + this.params + encodeURI(search) ).then( (response) => {
                    this.listItem = this.listParent.concat(response.data.data)
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        autoImput(){
            var listObject = []
            this.listItem.forEach(element => {
                if( this.listSelected && this.listSelected !== undefined && this.listSelected.isArray ){
                    if( this.listSelected.indexOf( element[ this.fields.index ] ) > -1 ){
                        listObject.push( element ) //this.listParent.push( element )
                    }
                } else {
                    listObject.push( element )
                }
            })
            this.$emit('update:listParent', listObject)
            this.$emit('update:listObject', this.listSelected)
            this.$emit('change', this.listSelected)
        },
        switchModal(name, mode){
            name    = name || 'modalressource'
            mode    = mode || 0
            //refresh = refresh || false
            this[name] = mode
        },
        textToshow( item ){
            let text   = ''
            let fields = this.fields.text.split(',')
            fields.forEach(element => {
                text += (text.length > 0) ? ' - ' + item[element] : item[element]
            })
            return text
        },
        mergeArrayObject(a, b, prop) {
            let reduced = a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]))
            return reduced.concat(b)
        }
    },
    components: {

    }
}
</script>