<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass" color="barrtextinfo">
            <v-breadcrumbs large divider=">" :items="contextStyle.breadcrumbs" :class="'pl-0'">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">{{ item.text }}</v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Nom"    v-model="form.ctt_lastname" ></v-text-field>
            <v-text-field label="Tel" v-model="form.ctt_phone"></v-text-field>
            <v-text-field label="Nom complet / Autre"  v-model="form.ctt_middlename"></v-text-field>
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="getCacheState('GEN')" v-model="form.ctt_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Prénom" v-model="form.ctt_firstname"></v-text-field>
            <v-text-field label="Mobile" v-model="form.ctt_mobile"></v-text-field>
            <v-text-field label="Courriel" v-model="form.ctt_email"></v-text-field>
            <v-select 
                item-text="typ_name" item-value="typ_id"
                :items="getCacheType('CTT')" v-model="form.ctt_typ_id" label="Type" single-line >
            </v-select>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-textarea label="Description" rows="2" v-model="form.ctt_customfields['ctt_description']"></v-textarea>
        </v-flex>
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>Société</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-layout row wrap>
                        <v-flex xs12 class="pa-2">
                            <v-text-field label="Dénomination sociale" v-model="form.ctt_company_name"></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Adresse</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-layout row wrap>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="Addresse 1" v-model="form.ctt_customfields['ctt_add_adr1']"></v-text-field>
                        </v-flex>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="Addresse 2" v-model="form.ctt_customfields['ctt_add_adr2']"></v-text-field>
                        </v-flex>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="Addresse 3" v-model="form.ctt_customfields['ctt_add_adr3']"></v-text-field>
                        </v-flex>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="Code postale" v-model="form.ctt_customfields['ctt_add_zip']"></v-text-field>
                        </v-flex>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="Ville" v-model="form.ctt_customfields['ctt_add_town']"></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        </v-layout>
    </v-card>
    <div class="text-right mt-3 mr-3">
        <v-btn class="mr-2" color="success" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">
        Enregistrer
        </v-btn>
        <v-btn color="primmary" href="/#/contacts/">
        Retour
        </v-btn>
    </div>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'user_card',
    props: ['contextStyle'],
    data: function () {
        return {
            search: '',
            footer: { 'items-per-page-all-text':"tout", 'items-per-page-text':'Ligne par page' },
            pagination: { totalItems: 0, rowsPerPage: 5, rowsPerItem: [5,15,25,35,{text:'Tout',value:-1}] },
            saveProgress: false,         // Enregistrement en cours...
            form:{
              ctt_id:'', ctt_sta_id: 'GEN01', ctt_typ_id: 'CTT01',
              ctt_firstname: '', ctt_lastname: '', ctt_middlename: '',
              ctt_phone: '', ctt_mobile: '', ctt_fax: '', ctt_adr_id: '',
              ctt_email: '', ctt_company_name: '', ctt_customfields: {}
            },
            locDateFormat: dateLocale, // Format de date
            items: [],
        }
    },
    computed: {

    },
    mounted:function (){
        if( parseInt( this.$route.params.ctt_id ) > 0 && this.$route.params.ctt_id !== undefined ){
            this.$http.get( '/contacts/' + this.$route.params.ctt_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
                this.form.ctt_customfields = (!this.form.ctt_customfields) ? {} : this.form.ctt_customfields
                if( Object.prototype.toString.call( this.form.ctt_customfields ) === '[object Array]' ) {
                    this.form.ctt_customfields = {}
                }
            })
            .catch( (error) => {
                this.$root.$toast({ color: 'warning', text: error })
            })
        }
    },
    methods: {
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.$route.params.ctt_id > 0 ){
                this.$http.put( '/contacts/' + this.$route.params.ctt_id, this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/contacts' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            } else {
                this.form.ctt_middlename = this.form.ctt_firstname + ' ' + this.form.ctt_lastname
                this.$http.post( '/contacts/', this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/contacts' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: { }
}
</script>