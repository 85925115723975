var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('PageHeader',{attrs:{"contextStyle":_vm.contextStyle},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{staticClass:"mr-4",attrs:{"small":"","outlined":"","color":"grey darken-2","href":"#/daycard/"}},[_vm._v("Archives")])]},proxy:true}])}),_c('v-card',{staticClass:"ma-2 pa-5 elevation-2",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[_c('DatePicker',{attrs:{"label":"Date","objectForm":_vm.objDayCard,"fieldName":"dca_date"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs3":""}},[_c('v-menu',{ref:"dca_time_start",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.modal.dca_time_start,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.modal, "dca_time_start", $event)},"update:return-value":function($event){return _vm.$set(_vm.modal, "dca_time_start", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Heure de début","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.objDayCard.dca_time_start),callback:function ($$v) {_vm.$set(_vm.objDayCard, "dca_time_start", $$v)},expression:"objDayCard.dca_time_start"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal.dca_time_start),callback:function ($$v) {_vm.$set(_vm.modal, "dca_time_start", $$v)},expression:"modal.dca_time_start"}},[(_vm.modal.dca_time_start)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.dca_time_start.save(_vm.objDayCard.dca_time_start)}},model:{value:(_vm.objDayCard.dca_time_start),callback:function ($$v) {_vm.$set(_vm.objDayCard, "dca_time_start", $$v)},expression:"objDayCard.dca_time_start"}}):_vm._e()],1)],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs3":""}},[_c('v-menu',{ref:"dca_time_end",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.modal.dca_time_end,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.modal, "dca_time_end", $event)},"update:return-value":function($event){return _vm.$set(_vm.modal, "dca_time_end", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Heure de fin","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.objDayCard.dca_time_end),callback:function ($$v) {_vm.$set(_vm.objDayCard, "dca_time_end", $$v)},expression:"objDayCard.dca_time_end"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal.dca_time_end),callback:function ($$v) {_vm.$set(_vm.modal, "dca_time_end", $$v)},expression:"modal.dca_time_end"}},[(_vm.modal.dca_time_end)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.dca_time_end.save(_vm.objDayCard.dca_time_end)}},model:{value:(_vm.objDayCard.dca_time_end),callback:function ($$v) {_vm.$set(_vm.objDayCard, "dca_time_end", $$v)},expression:"objDayCard.dca_time_end"}}):_vm._e()],1)],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[(!_vm.loadingData)?_c('AutocompleteMulti',{attrs:{"title":'Controleur',"ressource":'users',"listObject":_vm.objDayCard._pivot.managers,"listParent":_vm.objDayCard._data.managers,"fields":{ 
                    index: 'id', text:'firstName,lastName', autofocus: false,
                    search:'middleName', preprendIcon:'mdi-tooltip-account', multiSelect:true, prefetch: _vm.prefetchAc, params:'' 
            }},on:{"update:listObject":function($event){return _vm.$set(_vm.objDayCard._pivot, "managers", $event)},"update:list-object":function($event){return _vm.$set(_vm.objDayCard._pivot, "managers", $event)},"update:listParent":function($event){return _vm.$set(_vm.objDayCard._data, "managers", $event)},"update:list-parent":function($event){return _vm.$set(_vm.objDayCard._data, "managers", $event)}}}):_vm._e()],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[(!_vm.loadingData)?_c('AutocompleteMulti',{attrs:{"title":'Chef d\'équipe',"ressource":'users',"listObject":_vm.objDayCard._pivot.chiefs,"listParent":_vm.objDayCard._data.chiefs,"fields":{ 
                    index: 'id', text:'firstName,lastName', autofocus: false,
                    search:'middleName', preprendIcon:'mdi-account-multiple', multiSelect:true, prefetch: _vm.prefetchAc, params:'' 
            }},on:{"update:listObject":function($event){return _vm.$set(_vm.objDayCard._pivot, "chiefs", $event)},"update:list-object":function($event){return _vm.$set(_vm.objDayCard._pivot, "chiefs", $event)},"update:listParent":function($event){return _vm.$set(_vm.objDayCard._data, "chiefs", $event)},"update:list-parent":function($event){return _vm.$set(_vm.objDayCard._data, "chiefs", $event)}}}):_vm._e()],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[_c('v-text-field',{attrs:{"label":"Nombre d'employé","type":"number","disabled":_vm.modify,"prepend-icon":"mdi-account-group"},model:{value:(_vm.objDayCard.dca_worker_qty),callback:function ($$v) {_vm.$set(_vm.objDayCard, "dca_worker_qty", $$v)},expression:"objDayCard.dca_worker_qty"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[(!_vm.loadingData)?_c('AutocompleteMulti',{attrs:{"title":'Materiel',"ressource":'equipments',"listObject":_vm.objDayCard._pivot.equipments,"listParent":_vm.objDayCard._data.equipments,"fields":{ 
                    index: 'equ_id', text:'equ_name', autofocus: false,
                    search:'equ_name', preprendIcon:'mdi-hammer-screwdriver', multiSelect:true, prefetch: _vm.prefetchAc, params:'' 
            }},on:{"update:listObject":function($event){return _vm.$set(_vm.objDayCard._pivot, "equipments", $event)},"update:list-object":function($event){return _vm.$set(_vm.objDayCard._pivot, "equipments", $event)},"update:listParent":function($event){return _vm.$set(_vm.objDayCard._data, "equipments", $event)},"update:list-parent":function($event){return _vm.$set(_vm.objDayCard._data, "equipments", $event)}}}):_vm._e()],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[(!_vm.loadingData)?_c('AutocompleteMulti',{attrs:{"title":'Météo',"ressource":'types',"listObject":_vm.objDayCard._pivot.weather,"listParent":_vm.objDayCard._data.weather,"fields":{ 
                    index: 'typ_id', text:'typ_name', autofocus: false,
                    search:'typ_name', preprendIcon:'mdi-weather-partly-snowy-rainy', multiSelect:true, prefetch: _vm.prefetchAc, params:'typ_id=WEA%' 
            }},on:{"update:listObject":function($event){return _vm.$set(_vm.objDayCard._pivot, "weather", $event)},"update:list-object":function($event){return _vm.$set(_vm.objDayCard._pivot, "weather", $event)},"update:listParent":function($event){return _vm.$set(_vm.objDayCard._data, "weather", $event)},"update:list-parent":function($event){return _vm.$set(_vm.objDayCard._data, "weather", $event)}}}):_vm._e()],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[(!_vm.loadingData)?_c('AutocompleteMulti',{attrs:{"title":'Tache',"ressource":'tasktypes',"listObject":_vm.objDayCard._pivot.tasktypes,"listParent":_vm.objDayCard._data.tasktypes,"fields":{ 
                    index: 'tty_id', text:'tty_name', autofocus: false,
                    search:'tty_name', preprendIcon:'mdi-hammer-screwdriver', multiSelect:true, prefetch: _vm.prefetchAc, params:'' 
            }},on:{"update:listObject":function($event){return _vm.$set(_vm.objDayCard._pivot, "tasktypes", $event)},"update:list-object":function($event){return _vm.$set(_vm.objDayCard._pivot, "tasktypes", $event)},"update:listParent":function($event){return _vm.$set(_vm.objDayCard._data, "tasktypes", $event)},"update:list-parent":function($event){return _vm.$set(_vm.objDayCard._data, "tasktypes", $event)}}}):_vm._e()],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[_c('v-textarea',{attrs:{"label":"Commentaire ADP","rows":"2"},model:{value:(_vm.objDayCard.dca_note_a),callback:function ($$v) {_vm.$set(_vm.objDayCard, "dca_note_a", $$v)},expression:"objDayCard.dca_note_a"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[_c('v-textarea',{attrs:{"label":"Commentaire Robert","rows":"2"},model:{value:(_vm.objDayCard.dca_note_b),callback:function ($$v) {_vm.$set(_vm.objDayCard, "dca_note_b", $$v)},expression:"objDayCard.dca_note_b"}})],1)],1)],1),_c('v-toolbar',{staticClass:"elevation-0",attrs:{"extended":"","flat":"","color":"transparent"}},[((_vm.objDayCard.dca_id > 0 && _vm.objDayCard.dca_sta_id !== 'GEN03'))?_c('v-btn',{attrs:{"rounded":"","color":"error"},nativeOn:{"click":function($event){return _vm.closeForm()}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-close")]),_vm._v("Cloturer")],1):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"ml-3",attrs:{"rounded":"","color":"success","loading":_vm.saveProgress,"disabled":_vm.saveProgress},nativeOn:{"click":function($event){return _vm.saveForm()}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-content-save-outline")]),_vm._v("Enregistrer ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }