<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/areas/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title v-show="(form.are_id > 0)">Fiche {{form.are_id}} : {{form.are_name}}</v-toolbar-title>
        <v-toolbar-title v-show="(form.are_id == 'ajouter')">Nouvelle fiche</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-4 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom" v-model="form.are_name"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="typ_name" item-value="typ_id"
                :items="cacheType" v-model="form.are_typ_id" label="Type">
            </v-select>
            <!-- <v-btn @click="switchFieldAssoc" color="grey" outlined block dark class="mt-3">Association de champs</v-btn> -->
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="cacheState" v-model="form.are_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-switch v-model="form.force_code" label="Forcer la création du code"></v-switch>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-menu offset-y content-class="elevation-0" style="z-index:900">
                <template v-slot:activator="{ on }">
                    <v-btn :color="form.are_color" block dark class="mt-3" v-on="on">Couleur</v-btn>
                </template>
                <v-color-picker 
                    v-model="form.are_color" class="ma-2 elevation-0"
                    hide-canvas :swatches="swatches" show-swatches></v-color-picker>
            </v-menu>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-file-input @change="getFileLayer" ref="arefile" v-model="form.are_file" show-size label="Fichier(s)"></v-file-input>
        </v-flex>
        </v-layout>
    </v-card>
    <div class="ma-4 text-right">
        <v-btn color="success" class="mr-3" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">Enregistrer</v-btn>
        <v-btn color="primmary" href="/#/areas/">Retour</v-btn>
    </div>
    <v-dialog v-model="dialField" max-width="800" persistent>
        <v-card>
        <v-toolbar dark color="primary" dense flat>
            <v-toolbar-title class="white--text">Associer des champs</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
            <v-data-table :headers="layFieldsHeaders" :items="form.are_field" :items-per-page="5" class="elevation-1" >
            <template v-slot:[`header.id`]="">
                <v-btn @click="addFieldAssoc" x-small dark fab color='success'><v-icon>mdi-plus</v-icon></v-btn>
            </template>
            <template v-slot:body="{ items }">
            <tbody>
            <tr v-for="(item, index) in items" :key="index">
                <td class="text-xs-left"><v-text-field v-model="item.source"></v-text-field></td>
                <td class="text-xs-left">
                    <v-select :items="itemDes" v-model="item.destination" dense class="pt-3" item-text="text" item-value="value"></v-select>
                </td>
                <td class="text-xs-left"><v-switch v-model="item.infoPanel"></v-switch></td>
                <td class="text-xs-left"><v-switch v-model="item.infoPopup"></v-switch></td>
                <td class="text-xs-left"><v-icon style="cursor: pointer;" @click="deleteFieldAssoc(index)">mdi-delete</v-icon></td>
            </tr>
            </tbody>
            </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions class="pt-0 mr-3">
            <v-spacer></v-spacer>
            <v-btn color="grey" outlined @click.native="switchFieldAssoc">Fermer</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>

import { cacheGetters } from "@/store/cache.module"

export default {
    name: 'type_edit',
    props: ['contextStyle'],
    components: { },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            dialField   : false,
            cacheType: cacheGetters.getTypes('ARE') || [],
            cacheState: cacheGetters.getStates('GEN') || [],
            itemDes : [
                { text: 'Aucun', value: '' },
                { text: 'Code', value: 'are_code' },
            ],
            visibility: [
                {'value': 0,    'text': 'Visible'},
                {'value': 1,    'text': 'Désactivé'}
            ],
            swatches: [
                ['#000000', '#FFFFFF', '#3388FF'], ['#FF0000', '#AA0000', '#550000'], ['#FFFF00', '#AAAA00', '#555500'], ['#00FF00', '#00AA00', '#005500'],
                ['#0000FF', '#0000AA', '#000055'] //['#00FFFF', '#00AAAA', '#005555'],
            ],
            form:{
              are_id:'', are_zon_id: 1, are_sta_id: 'GEN01', are_typ_id: 'ARE01',
              are_name: '', are_color: '#000000', are_field: [], are_created_at: '', are_file: null,
              force_code: false
            },
            layFieldsHeaders: [
                { text: 'Source',       value:'source',  width: '34' },
                { text: 'Association',  value:'destination',  width: '34' },
                { text: 'Zone info',    value:'infoPanel',  width: '34', sortable: false },
                { text: 'Popup carte',  value:'infoPopup',  width: '34', sortable: false },
                { text: '-',            value:'id',  width: '34', sortable: false }
            ],
        }
    },
    computed: {
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
        }
    },
    mounted:function (){
        if( ( this.$route.params.are_id ) && this.$route.params.are_id > 0 ){
            this.getForm(this.$route.params.are_id)
        } else {
            this.form.are_id = 'ajouter' //this.$route.params.are_id
        }
    },
    methods: {
        getForm(id) {
            this.$http.get( '/areas/' + id )
            .then( (response) => {
                if( response.data.data.are_field == 'null' ){
                    response.data.data.are_field = []
                } else {
                    try {
                        response.data.data.are_field = JSON.parse(response.data.data.are_field)
                    } catch {
                        response.data.data.are_field = []
                    }
                }
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
            })
            .catch( (error) => {
                this.$root.$toast({ color: 'warning', text: error })
            })
        },
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            var formFile = new FormData()
            for (var prop in this.form) {
                if( prop == 'are_field' ){
                    formFile.append(prop, JSON.stringify( this.form[prop] ))
                } else {
                    formFile.append(prop, this.form[prop])
                }
            }
            if( this.form.are_file ) {
                formFile.append('are_file', this.form.are_file, this.form.are_file.name)
            }

            if( this.$route.params.are_id > 0 && this.$route.params.are_id !== 'ajouter' ){
                this.$http.post( '/areas/' + this.$route.params.are_id, formFile ).then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/areas' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                })
            } else {
                this.$http.post( '/areas/', formFile ).then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/areas' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                })
            }
        },
        getFileLayer(file){
            if( this.form.are_name.length == 0 ){
                let nameTab = file.name.split(".")
                this.form.are_name = nameTab[0]
            }
            this.form.are_source_filename = file.name
        },
        switchFieldAssoc(){
            this.dialField = !this.dialField
        },
        addFieldAssoc(){
            if( this.form.are_field === null || this.form.are_field === undefined ){
                this.form.are_field = []
            }
            this.form.are_field.push( { source: '', destination: '', infoPanel: false, infoPopup: false } )
        },
        deleteFieldAssoc(id){
            this.form.are_field.splice(id, 1);
        }
    }
}
</script>