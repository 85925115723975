<template>
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" class="ma-4 rounded-lg elevation-2">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-1">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" color="success" outlined :loading="loading.getXls" class="ma-2">
                                <v-icon dark>mdi-file-excel</v-icon>
                            </v-btn>
                        </template>
                        <span>Telecharger xlsx</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.dca_date`]="{ item }">
            <v-chip small outlined label>{{ $date(item.dca_date).format('DD/MM/YYYY') }}</v-chip>
        </template>
        <template v-slot:[`item.dca_time_start`]="{ item }">
            <v-chip small outlined label>{{ item.dca_time_start }}</v-chip>
        </template>
        <template v-slot:[`item.dca_time_end`]="{ item }">
            <v-chip small outlined label>{{ item.dca_time_end }}</v-chip>
        </template>
        <template v-slot:[`item.dca_typ_id`]="{ item }">
            <v-chip small outlined label :color="getCacheType('DCA', item.dca_typ_id).typ_color">
                {{ getCacheType('DCA', item.dca_typ_id).typ_name }}
            </v-chip>
        </template>
        <template v-slot:[`item.dca_sta_id`]="{ item }">
            <v-chip small outlined label :color="getCacheState('GEN', item.dca_sta_id).sta_color">
                {{ getCacheState('GEN', item.dca_sta_id).sta_label }}
            </v-chip>
        </template>
        <template v-slot:[`item.dca_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/daycard/' + item.dca_id + ''">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Ouvrir
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
</template>

<script>
import { cacheGetters } from "@/store/cache.module"
import { dcaObj }       from '@/mixins/dca_obj.js'

export default {
    name: 'site_edit',
    props: [ 'toast' ],
    mixins: [ dcaObj ],
    data: function () {
        return {
            loading: { getgetTaskItems: false },
            validFrom: true,
            items        : [],
            taskSelected : {},
            selectFields : {},
            actionTask   : [],
            typeState: cacheGetters.getTypes(),

            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Date',    align: 'left', value: 'dca_date'},
                { text: 'Début',   align: 'left', value: 'dca_typ_id'},
                { text: 'Fin',     align: 'left', value: 'dca_time_start'},
                { text: 'Agent',   align: 'left', value: 'dca_time_end'},
                { text: 'Statut',  align: 'left', value: 'dca_sta_id'},
                { text: 'Action',  align: 'left', value: 'dca_id'},
            ],
        }
    },
    beforeMount: function(){
        this.getDayCardIndex().then( () => {
            this.items = this.listDayCard
        })
    },
    mounted: function (){

    },
    methods: {
        getCacheType: cacheGetters.getTypes,
        getCacheState   : cacheGetters.getStates,
    },
}
</script>