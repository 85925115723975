var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('PageHeader',{attrs:{"contextStyle":_vm.contextStyle}}),_c('v-card',{staticClass:"ma-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"footer-props.items-per-page-text":"Lignes par page","hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"rounded-lg ma-0 pa-0",staticStyle:{"background-color":"#FAFAFA"}},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"6","md":"6"}},[_c('v-text-field',{staticClass:"pa-2 rounded-xl",attrs:{"append-icon":"mdi-magnify","label":"Recherche","outlined":"","dense":"","clearable":"","single-line":"","hide-details":""},on:{"blur":function($event){return _vm.saveSearchToCache()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right pa-2",attrs:{"cols":"6","md":"6"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"success","depressed":"","rounded":"","to":"/areas/0"}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-pencil-plus-outline")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Créer")])],1)],1)],1)]},proxy:true},{key:"item.are_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":"","color":_vm.getCacheType('SIT', item.are_typ_id).typ_color}},[_vm._v(" "+_vm._s(item.are_name)+" ")])]}},{key:"item.are_sta_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":"","color":_vm.getCacheState('GEN', item.are_sta_id).typ_color}},[_vm._v(" "+_vm._s(_vm.getCacheState('GEN', item.are_sta_id).sta_label)+" ")])]}},{key:"item.are_updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.are_updated_at).format('DD MMMM YYYY'))+" ")]}},{key:"item.are_color",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":item.are_color}})]}},{key:"item.are_id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"grey","outlined":"","to":'/areas/' + item.are_id}},[_vm._v("Modifier")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.deleteItem(item.are_id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"mt-2",attrs:{"value":true,"color":"error","icon":"mdi-alert"}},[_vm._v("Aucune données")])],1)],2)],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"text-left pl-8",attrs:{"xs6":""}},[_c('span',[_vm._v(_vm._s(_vm.pagination.totalItems)+" résultats")])]),_c('v-flex',{staticClass:"text-right pr-0",attrs:{"xs6":""}},[_c('v-pagination',{attrs:{"length":_vm.pagination.pageCount,"total-visible":7},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }