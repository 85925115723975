<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass">
            <v-icon large :color="contextStyle.titleColor" :class="contextStyle.titleIcon">{{ contextStyle.icon }}</v-icon>
            <span>{{ contextStyle.title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>

    <v-card class="ma-4 rounded-lg">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-1">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn rounded outlined depressed text @click="modalUpload = true" color="orange" class="mr-2 white--text" v-on="on">
                                <v-icon dark>mdi-application-import</v-icon><span class="hidden-sm-and-down"></span>
                            </v-btn>
                        </template>
                        <span>Injecter / modifier des contacts</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn rounded outlined depressed text color="primary" class="mr-2 white--text" v-on="on" @click="downloadContact()">
                                <v-icon dark>mdi-file-excel</v-icon><span class="hidden-sm-and-down"></span>
                            </v-btn>
                        </template>
                        <span>Télécharger la liste des contacts</span>
                    </v-tooltip>
                    <v-btn color="success" rounded depressed class="white--text" to="/contacts/0">
                        <v-icon left dark>mdi-plus-circle</v-icon>
                        <span class="hidden-sm-and-down">Créer</span>
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.ctt_middlename`]="{ item }">
            <span class="text-xs-left font-weight-bold">{{ item.ctt_middlename }}</span>
        </template>
        <template v-slot:[`item.ctt_sta_id`]="{ item }">
            <v-chip small outlined label>{{ getCacheState('GEN', item.ctt_sta_id).sta_label }}</v-chip>
        </template>
        <template v-slot:[`item.ctt_typ_id`]="{ item }">
            <v-chip small outlined label>{{ getCacheType('CTT', item.ctt_typ_id).typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.ctt_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/contacts/' + item.ctt_id + ''">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>

    <v-dialog v-model="modalUpload" max-width="900" >
        <v-card color="grey lighten-4" flat>
            <v-toolbar color="primary" dark class="elevation-2" height="50px">
                <v-icon class="mr-3">mdi-chevron-down</v-icon>
                <v-toolbar-title>Importer des contacts</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon color="" @click.native="modalUpload = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-layout row wrap>
                <v-flex xs6 class="pa-2">
                    <v-file-input ref="contact" color="orange"
                        @change="getFile($event, 'contact')" label="Selectionner un fichier"
                        v-model="form.file_contact">
                    </v-file-input>
                </v-flex>
                <v-flex xs4 class="pa-2">
                    <v-select 
                        item-text="typ_name" item-value="typ_id"
                        :items="getCacheType('CTT')" v-model="form.ctt_typ_id" label="Type" single-line >
                    </v-select>
                </v-flex>
                <v-flex xs2 class="pa-2">
                    <v-btn color="green" class="mt-3" @click="sendContactFile('contact')" :disabled="(!validUplodContact || saveProgress)">Envoyer</v-btn>
                </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'states',
    props: ['contextStyle'],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Nom',       align: 'left',  value: 'ctt_middlename'},
            { text: 'Etat',      align: 'left',  value: 'ctt_sta_id'},
            { text: 'Type',      align: 'left',  value: 'ctt_typ_id'},
            { text: 'Téléphone', align: 'left',  value: 'ctt_phone'},
            { text: 'Mobile',    align: 'left',  value: 'ctt_mobile'},
            { text: 'Email',     align: 'right', value: 'ctt_email' },
            { text: 'Modifier',  align: 'right', value: 'ctt_id' }
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            modalUpload: false,
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
            form: { 
                file_contact: null, file_contact_name: '', file_contact_source: '',
                ctt_typ_id: 'CTT01'
            }
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        validUplodContact(){
            if( this.form.file_contact_name.length > 0 ){
                return true
            } else {
                return false
            }
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('contacts') || ''
        this.$http.get( '/contacts/?per_page=false' ).then( (response) => {
            this.items = response.data.data
        })
    },
    methods: {
        getStates(){
            this.items = []
            
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            //this.pagination.page  = (index - 1)
        },
        downloadContact(){
            this.$http.customRequest({ method: 'get', url: '/report/contact/?per_page=false', responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            })
        },
        getFile(file, scope){
            if( file !== undefined && this.form['file_' + scope + '_name'].length == 0 ){
                //let nameTab = file.name.split(".")
                //this.form['file_' + scope + '_name'] = nameTab[0]
                this.form['file_' + scope + '_name'] = file.name
            } else {
                this.form['file_' + scope + '_name'] = ''
            }
        },
        sendContactFile(scope) {
            // Enregistrement du formulaire
            this.saveProgress = true
            var formFile = new FormData()
            for (var prop in this.form) {
                if( prop == scope ){
                    formFile.append(prop, JSON.stringify( this.form[prop] ))
                } else {
                    formFile.append(prop, this.form[prop])
                }
            }
            if( this.form['file_' + scope] ) {
                formFile.append('file_' + scope, this.form['file_' + scope], this.form['file_' + scope].name)
            }
            this.$http.post( '/contacts/upload/', formFile )
            .then( (response) => {
                this.$root.$toast({ color: 'success', text: 'Données enregistrée !' })
                this.saveProgress = false
                this.modalUpload  = false
                for( let prop in response.data ){
                    if( Array.isArray( response.data[prop].error ) ){
                        this.processInfo = response.data[prop].error
                    }
                }
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                this.saveProgress = false
                this.modalUpload  = false
            })

        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('contacts', this.search)
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        
    }
}
</script>