/* *****************************
    Objet : Equipment
******************************** */
//import { cacheGetters } from "@/store/cache.module"

export const equObj = {
    data: function () {
        return {
            objEquipment: {
                equ_id             : 0, 
                equ_uid            : '', 
                equ_nfcid          : '', 
                equ_sta_id         : 'GEN01', 
                equ_zon_id         : null, 
                equ_name           : '', 
                equ_serial         : '', 
                equ_customfields   : '', 
                equ_usr_id_created : '', 
                equ_created_at     : '', 
                equ_updated_at     : '', 
                _table: {},
                _data:  {},
                _pivot: {},
                _info: { 
                    
                }
            },
            objEquipmentClone: {},
            listEquipment: [],
            metaEquipment: {},
        }
    },
    computed: {
        
    },
    beforeMount: function (){
        this.objEquipmentClone = JSON.stringify( this.objEquipment )
    },
    methods: {
        //getEquipmentFilter(page, perPage){
        //    page    = page || 1
        //    perPage = perPage || 'false'
        //    let filter = cacheGetters.getFilter()
        //    var searchCriteria = '?page='+ page +'&per_page=' + perPage + '&extended=withtree,withactivealert'
        //    for( var prop in filter ){
        //        if( filter[prop] !== null && filter[prop] !== undefined && filter[prop].length > 0 ){
        //            searchCriteria += '&' + prop + '=' + filter[prop]
        //        }
        //    }
        //    return searchCriteria
        //},
        getEquipmentShow(options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                if( options.length > 0 ){
                    options = '/' + options
                }
                this.$http.get( '/equipments/' + this.objEquipment.equ_id + options).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( Object.prototype.toString.call(response.data.data[prop]) === '[object Array]' && this.objEquipment._data[prop] ){
                            this.objEquipment._data[prop] = response.data.data[prop]
                            //this.objEquipment._pivot[prop] = [...this.objEquipment._data[prop].map(a => a[this.objEquipment._info[prop].id])]
                        } else {
                            this.objEquipment[prop] = response.data.data[prop]
                        }
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getEquipmentIndex(page, perPage, options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                this.listEquipment = []
                //let searchCriteria = this.getEquipmentFilter(page, perPage)
                let searchCriteria = ''

                this.$http.get( '/equipments/' + searchCriteria + options ).then( (response) => {
                    this.metaEquipment = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.listEquipment = response.data.data
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        postEquipmentStore(){
            return new Promise( (resolve, reject) => {
                this.$http.post( '/equipments', this.objEquipment ).then( (response) => {
                    this.objEquipment.equ_id = (response.data.data) ? response.data.data.equ_id : response.data.equ_id
                    resolve('resolved')
                    
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        putEquipmentStore(obj){
            return new Promise( (resolve, reject) => {
                obj = (obj) ? obj : this.objEquipment // partial update
                this.$http.put( '/equipments/' + obj.equ_id, obj ).then( (response) => {
                    this.objEquipment.equ_id = (response.data.data) ? response.data.data.equ_id : response.data.equ_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        deleteEquipmentDestroy(){
            return new Promise( (resolve, reject) => {
                this.$http.delete( '/equipments/' + this.objEquipment.equ_id ).then( (response) => {
                    this.objEquipment.equ_id = response.data.equ_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        cloneEquipmentObj(){
            this.objEquipmentClone = JSON.stringify( this.objEquipment )
        },
        cleanEquipmentObj(){
            var equipmentClone = JSON.parse( this.objEquipmentClone )
            var prop
            for( prop in equipmentClone ){
                if( this.objEquipment[prop] !== undefined ){
                    this.objEquipment[prop] = equipmentClone[prop]
                }
            }
        },
    }
}