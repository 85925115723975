<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle">
      <!-- <template #right><v-btn small><v-icon small color="success">mdi-file-excel</v-icon></v-btn></template> -->
    </PageHeader>

    <EventList 
        :routeState="{ siteView:false, siteEdit:true, view:false, edit:true, add:true, state: 'modifier' }" 
        :eve_xxx_id="'all'" :table_id="'zon,tsk'" :typfilter="'MEV'" :stafilter="'TSK'" title="Devis"/>

</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import EventList from '@/components/events/EventList'

import { cacheGetters, cacheMutations } from "../store/cache.module"
//import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'equipments',
    props: ['toast', 'contextStyle'],
    components: {
        PageHeader,
        EventList
    },
    data: function () {
        return {
            loading: { getXls: false, getTasks: false },         // Enregistrement en cours...
            items: [],
            enableAdd: true,
            modalEvent : false,
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    created(){

    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('equipment') || ''
        this.getItems()
    },
    methods: {
        async getItems(){
            this.items = []
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('equipment', this.search)
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    beforeDestroy() {
        //this.subTask.unsubscribe()
    },
}
</script>