import Vue from "vue";
import { cacheData } from "../store/cache.module"
import { scopeCredential } from "../scopeCredential"

export const usrState = Vue.observable({
  role : '',
  genStates: []
})

export const usrGetters = {
  genState: () => {
    return usrState.genStates
  },
  getRole: () => {
    return usrState.role
  },
  getScopeRight: (scope, item) => {
    if( scopeCredential[scope] !== undefined && scopeCredential[scope][item] !== undefined ){
      if( scopeCredential[scope][item].indexOf( cacheData.user.role ) > -1 ){
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
}

export const usrActions = {

}

export const usrMutations = {
  setGenStates( genStates ) {
    usrState.genStates = genStates;
  },

}