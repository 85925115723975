/* *****************************
    Objet : DayCard
******************************** */
//import { cacheGetters } from "@/store/cache.module"

export const dcaObj = {
    data: function () {
        return {
            objDayCard: {
                dca_id         : 0, 
                dca_sta_id     : 'GEN01', 
                dca_typ_id     : 'DCA01', 
                dca_zon_id     : '', 
                dca_date       : '', 
                dca_date_start : '', 
                dca_time_start : '', 
                dca_date_end   : '', 
                dca_time_end   : '', 
                dca_worker_qty : '', 
                dca_note_a     : '', 
                dca_note_b     : '', 
                dca_usr_id_created: '', 
                dca_usr_id_updated: '', 
                dca_created_at    : '', 
                dca_updated_at    : '', 
                _table: {},
                _data:  { equipments: [], managers: [], tasktypes: [], chiefs: [], weather: [] },
                _pivot: { equipments: [], managers: [], tasktypes: [], chiefs: [], weather: [] },
                _info: { 
                    equipments: {id: 'equ_id'}, managers: {id: 'id'}, 
                    tasktypes: {id: 'tty_id'}, chiefs: {id: 'id'}, weather: {id: 'typ_id'}
                }
            },
            objDayCardClone: {},
            listDayCard: [],
            metaDayCard: {},
        }
    },
    computed: {
        
    },
    beforeMount: function (){
        this.objDayCardClone = JSON.stringify( this.objDayCard )
    },
    methods: {
        //getDayCardFilter(page, perPage){
        //    page    = page || 1
        //    perPage = perPage || 'false'
        //    let filter = cacheGetters.getFilter()
        //    var searchCriteria = '?page='+ page +'&per_page=' + perPage + '&extended=withtree,withactivealert'
        //    for( var prop in filter ){
        //        if( filter[prop] !== null && filter[prop] !== undefined && filter[prop].length > 0 ){
        //            searchCriteria += '&' + prop + '=' + filter[prop]
        //        }
        //    }
        //    return searchCriteria
        //},
        getDayCardShow(options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                if( options.length > 0 ){
                    options = '/' + options
                }
                this.$http.get( '/daycards/' + this.objDayCard.dca_id + options).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( Object.prototype.toString.call(response.data.data[prop]) === '[object Array]' && this.objDayCard._data[prop] ){
                            this.objDayCard._data[prop] = response.data.data[prop]
                            this.objDayCard._pivot[prop] = [...this.objDayCard._data[prop].map(a => a[this.objDayCard._info[prop].id])]
                        } else {
                            this.objDayCard[prop] = response.data.data[prop]
                        }
                    }
                    //this.objDayCard.dca_custom_fields = (!this.objDayCard.dca_custom_fields) ? {} : this.objDayCard.dca_custom_fields
                    //if( Object.prototype.toString.call( this.objDayCard.dca_custom_fields ) === '[object Array]' ) {
                    //    this.objDayCard.dca_custom_fields = {}
                    //}
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getDayCardIndex(page, perPage, options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                this.listDayCard = []
                //let searchCriteria = this.getDayCardFilter(page, perPage)
                let searchCriteria = ''

                this.$http.get( '/daycards/' + searchCriteria + options ).then( (response) => {
                    this.metaDayCard = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.listDayCard = response.data.data
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        postDayCardStore(){
            return new Promise( (resolve, reject) => {
                this.$http.post( '/daycards', this.objDayCard ).then( (response) => {
                    this.objDayCard.dca_id = (response.data.data) ? response.data.data.dca_id : response.data.dca_id
                    resolve('resolved')
                    
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        putDayCardStore(obj){
            return new Promise( (resolve, reject) => {
                obj = (obj) ? obj : this.objDayCard // partial update
                this.$http.put( '/daycards/' + obj.dca_id, obj ).then( (response) => {
                    this.objDayCard.dca_id = (response.data.data) ? response.data.data.dca_id : response.data.dca_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        deleteDayCardDestroy(){
            return new Promise( (resolve, reject) => {
                this.$http.delete( '/daycards/' + this.objDayCard.dca_id ).then( (response) => {
                    this.objDayCard.dca_id = response.data.dca_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        cloneDayCardObj(){
            this.objDayCardClone = JSON.stringify( this.objDayCard )
        },
        cleanDayCardObj(){
            var dayCardClone = JSON.parse( this.objDayCardClone )
            var prop
            for( prop in dayCardClone ){
                if( this.objDayCard[prop] !== undefined ){
                    this.objDayCard[prop] = dayCardClone[prop]
                }
            }
        },
    }
}