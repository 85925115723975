<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle">
    </PageHeader>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom"  v-model="objTasktypes.tty_name"></v-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Description"  v-model="objTasktypes.tty_description"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-menu offset-y content-class="elevation-0" style="z-index:900">
                <template v-slot:activator="{ on }">
                    <v-btn :color="objTasktypes.tty_color" block dark class="mt-3" v-on="on"><v-icon left>mdi-palette</v-icon>Couleur</v-btn>
                </template>
                <v-color-picker v-model="objTasktypes.tty_color" class="ma-2 elevation-0" hide-canvas :swatches="swatches" show-swatches></v-color-picker>
            </v-menu>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="cacheState" v-model="objTasktypes.tty_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn rounded color="error" @click.native="deleteForm()"><v-icon left dark>mdi-close</v-icon>Supprimer</v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded text href="/#/tasktypes/"><v-icon left dark>mdi-close</v-icon>Annuler</v-btn>
        <v-btn rounded color="success" class="ml-3" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">
            <v-icon left dark>mdi-content-save-outline</v-icon>Enregistrer
        </v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import { cacheGetters } from "@/store/cache.module"
import { ttyObj }       from '@/mixins/tty_obj.js'

export default {
    name: 'EquipmentEdit',
    props: [ 'contextStyle'],
    mixins: [ ttyObj ],
    components: { PageHeader },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            loadingData: true,
            prefetchAc: true,
            modify: false,
            swatches: [
                ['#FF0000', '#AA0000', '#550000'], ['#FFFF00', '#AAAA00', '#555500'], ['#00FF00', '#00AA00', '#005500'],
                ['#00FFFF', '#00AAAA', '#005555'], ['#0000FF', '#0000AA', '#000055'],
            ],
            cacheState: cacheGetters.getStates('GEN') || [],
        }
    },
    computed: { },
    beforeMount: function(){},
    mounted:function (){
        if( ( this.$route.params.tty_id ) && this.$route.params.tty_id > 0 ){
            this.modify = true
            this.objTasktypes.tty_id = this.$route.params.tty_id
            this.prefetchAc = false
            this.getForm()
        } else {
            this.loadingData = false
        }
    },
    methods: {
        async getForm(){
            await this.getTasktypesShow()
            this.loadingData = false
            
        },
        async saveForm () {
            if( this.objTasktypes.tty_id > 0 ){
                await this.putTasktypesStore()
                this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                this.$router.push({ path: '/tasktypes' })
            } else {
                await this.postTasktypesStore()
                this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                this.$router.push({ path: '/tasktypes' })
            }
        },
        async deleteForm(){
            this.objTasktypes.dca_sta_id = 'GEN03'
            await this.putTasktypesStore()
            this.$root.$toast({ color: 'success', text: 'Fiche supprimée !' })
            this.$router.push({ path: '/tasktypes' })
        },
        getCacheType: cacheGetters.getTypes,
    }
}
</script>