<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle">
    </PageHeader>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Entité"  v-model="objEquipment.equ_name"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Numéro de série"  v-model="objEquipment.equ_serial"></v-text-field>
        </v-flex>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn rounded color="error" @click.native="deleteForm()"><v-icon left dark>mdi-close</v-icon>Supprimer</v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded text href="/#/equipments/"><v-icon left dark>mdi-close</v-icon>Annuler</v-btn>
        <v-btn rounded color="success" class="ml-3" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">
            <v-icon left dark>mdi-content-save-outline</v-icon>Enregistrer
        </v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import { cacheGetters } from "@/store/cache.module"
import { equObj }       from '@/mixins/equ_obj.js'

export default {
    name: 'EquipmentEdit',
    props: ['contextStyle'],
    mixins: [ equObj ],
    components: { PageHeader },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            loadingData: true,
            prefetchAc: true,
            modify: false,
        }
    },
    computed: { },
    beforeMount: function(){},
    mounted:function (){
        if( ( this.$route.params.equ_id ) && this.$route.params.equ_id > 0 ){
            this.modify = true
            this.objEquipment.equ_id = this.$route.params.equ_id
            this.prefetchAc = false
            this.getForm()
        } else {
            this.loadingData = false
        }
    },
    methods: {
        async getForm(){
            await this.getEquipmentShow()
            this.loadingData = false
            
        },
        async saveForm () {
            if( this.objEquipment.equ_id > 0 ){
                await this.putEquipmentStore()
                this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                this.$router.push({ path: '/equipments' })
            } else {
                await this.postEquipmentStore()
                this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                this.$router.push({ path: '/equipments' })
            }
        },
        async deleteForm(){
            this.objEquipment.dca_sta_id = 'GEN03'
            await this.putEquipmentStore()
            this.$root.$toast({ color: 'success', text: 'Fiche supprimée !' })
            this.$router.push({ path: '/equipments' })
        },
        getCacheType: cacheGetters.getTypes,
    }
}
</script>