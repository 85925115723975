/* *****************************
    Objet : Tasktypes
******************************** */
//import { cacheGetters } from "@/store/cache.module"

export const ttyObj = {
    data: function () {
        return {
            objTasktypes: {
                tty_id             : 0, 
                tty_zon_id : null,
                tty_are_id : null,
                tty_sta_id : 'GEN01',
                tty_typ_id : 'TTY01',
                tty_typ_id_layer : 0,
                tty_color : '#3388ff',
                tty_name : '',
                tty_description : '',
                _table: {},
                _data:  {},
                _pivot: {},
                _info: { 
                    
                }
            },
            objTasktypesClone: {},
            listTasktypes: [],
            metaTasktypes: {},
        }
    },
    computed: {
        
    },
    beforeMount: function (){
        this.objTasktypesClone = JSON.stringify( this.objTasktypes )
    },
    methods: {
        //getTasktypesFilter(page, perPage){
        //    page    = page || 1
        //    perPage = perPage || 'false'
        //    let filter = cacheGetters.getFilter()
        //    var searchCriteria = '?page='+ page +'&per_page=' + perPage + '&extended=withtree,withactivealert'
        //    for( var prop in filter ){
        //        if( filter[prop] !== null && filter[prop] !== undefined && filter[prop].length > 0 ){
        //            searchCriteria += '&' + prop + '=' + filter[prop]
        //        }
        //    }
        //    return searchCriteria
        //},
        getTasktypesShow(options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                this.$http.get( '/tasktypes/' + this.objTasktypes.tty_id + options).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( Object.prototype.toString.call(response.data.data[prop]) === '[object Array]' && this.objTasktypes._data[prop] ){
                            this.objTasktypes._data[prop] = response.data.data[prop]
                            //this.objTasktypes._pivot[prop] = [...this.objTasktypes._data[prop].map(a => a[this.objTasktypes._info[prop].id])]
                        } else {
                            this.objTasktypes[prop] = response.data.data[prop]
                        }
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getTasktypesIndex(page, perPage, options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                this.listTasktypes = []
                //let searchCriteria = this.getTasktypesFilter(page, perPage)
                let searchCriteria = ''

                this.$http.get( '/tasktypes/' + searchCriteria + options ).then( (response) => {
                    this.metaTasktypes = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.listTasktypes = response.data.data
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        postTasktypesStore(){
            return new Promise( (resolve, reject) => {
                this.$http.post( '/tasktypes', this.objTasktypes ).then( (response) => {
                    this.objTasktypes.tty_id = (response.data.data) ? response.data.data.tty_id : response.data.tty_id
                    resolve('resolved')
                    
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        putTasktypesStore(obj){
            return new Promise( (resolve, reject) => {
                obj = (obj) ? obj : this.objTasktypes // partial update
                this.$http.put( '/tasktypes/' + obj.tty_id, obj ).then( (response) => {
                    this.objTasktypes.tty_id = (response.data.data) ? response.data.data.tty_id : response.data.tty_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        deleteTasktypesDestroy(){
            return new Promise( (resolve, reject) => {
                this.$http.delete( '/tasktypes/' + this.objTasktypes.tty_id ).then( (response) => {
                    this.objTasktypes.tty_id = response.data.tty_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        cloneTasktypesObj(){
            this.objTasktypesClone = JSON.stringify( this.objTasktypes )
        },
        cleanTasktypesObj(){
            var tasktypesClone = JSON.parse( this.objTasktypesClone )
            var prop
            for( prop in tasktypesClone ){
                if( this.objTasktypes[prop] !== undefined ){
                    this.objTasktypes[prop] = tasktypesClone[prop]
                }
            }
        },
    }
}