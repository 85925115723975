<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle">
        <template #right>
            <v-btn small outlined class="mr-4" color="grey darken-2" href="#/daycard/" >Archives</v-btn>
        </template>
    </PageHeader>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <DatePicker label="Date" :objectForm="objDayCard" fieldName='dca_date'></DatePicker>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-menu ref="dca_time_start" v-model="modal.dca_time_start" :close-on-content-click="false" :nudge-right="40" :return-value.sync="modal.dca_time_start" 
            transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="objDayCard.dca_time_start" label="Heure de début" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on">
                    </v-text-field>
                </template>
                <v-time-picker v-if="modal.dca_time_start" format="24hr" v-model="objDayCard.dca_time_start" full-width @click:minute="$refs.dca_time_start.save(objDayCard.dca_time_start)"></v-time-picker>
            </v-menu>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-menu ref="dca_time_end" v-model="modal.dca_time_end" :close-on-content-click="false" :nudge-right="40" :return-value.sync="modal.dca_time_end" 
            transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="objDayCard.dca_time_end" label="Heure de fin" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on">
                    </v-text-field>
                </template>
                <v-time-picker v-if="modal.dca_time_end" format="24hr" v-model="objDayCard.dca_time_end" full-width @click:minute="$refs.dca_time_end.save(objDayCard.dca_time_end)"></v-time-picker>
            </v-menu>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <AutocompleteMulti v-if="!loadingData"
                :title="'Controleur'" :ressource="'users'"
                :listObject.sync="objDayCard._pivot.managers" :listParent.sync="objDayCard._data.managers" 
                :fields="{ 
                    index: 'id', text:'firstName,lastName', autofocus: false,
                    search:'middleName', preprendIcon:'mdi-tooltip-account', multiSelect:true, prefetch: prefetchAc, params:'' 
            }"></AutocompleteMulti>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <AutocompleteMulti v-if="!loadingData"
                :title="'Chef d\'équipe'" :ressource="'users'"
                :listObject.sync="objDayCard._pivot.chiefs" :listParent.sync="objDayCard._data.chiefs" 
                :fields="{ 
                    index: 'id', text:'firstName,lastName', autofocus: false,
                    search:'middleName', preprendIcon:'mdi-account-multiple', multiSelect:true, prefetch: prefetchAc, params:'' 
            }"></AutocompleteMulti>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Nombre d'employé" v-model="objDayCard.dca_worker_qty" type="number" :disabled="modify" prepend-icon="mdi-account-group"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <AutocompleteMulti v-if="!loadingData"
                :title="'Materiel'" :ressource="'equipments'"
                :listObject.sync="objDayCard._pivot.equipments" :listParent.sync="objDayCard._data.equipments" 
                :fields="{ 
                    index: 'equ_id', text:'equ_name', autofocus: false,
                    search:'equ_name', preprendIcon:'mdi-hammer-screwdriver', multiSelect:true, prefetch: prefetchAc, params:'' 
            }"></AutocompleteMulti>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <AutocompleteMulti v-if="!loadingData"
                :title="'Météo'" :ressource="'types'"
                :listObject.sync="objDayCard._pivot.weather" :listParent.sync="objDayCard._data.weather" 
                :fields="{ 
                    index: 'typ_id', text:'typ_name', autofocus: false,
                    search:'typ_name', preprendIcon:'mdi-weather-partly-snowy-rainy', multiSelect:true, prefetch: prefetchAc, params:'typ_id=WEA%' 
            }"></AutocompleteMulti>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <AutocompleteMulti v-if="!loadingData"
                :title="'Tache'" :ressource="'tasktypes'"
                :listObject.sync="objDayCard._pivot.tasktypes" :listParent.sync="objDayCard._data.tasktypes" 
                :fields="{ 
                    index: 'tty_id', text:'tty_name', autofocus: false,
                    search:'tty_name', preprendIcon:'mdi-hammer-screwdriver', multiSelect:true, prefetch: prefetchAc, params:'' 
            }"></AutocompleteMulti>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-textarea label="Commentaire ADP" rows="2" v-model="objDayCard.dca_note_a" ></v-textarea>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-textarea label="Commentaire Robert" rows="2" v-model="objDayCard.dca_note_b" ></v-textarea>
        </v-flex>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <!-- <v-btn rounded text href="/#/daycard/"><v-icon left dark>mdi-close</v-icon>Annuler</v-btn> -->
        <v-btn v-if="(objDayCard.dca_id > 0 && objDayCard.dca_sta_id !== 'GEN03')" rounded color="error" @click.native="closeForm()"><v-icon left dark>mdi-close</v-icon>Cloturer</v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded color="success" class="ml-3" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">
            <v-icon left dark>mdi-content-save-outline</v-icon>Enregistrer
        </v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import DatePicker from '@/components/ui/DatePicker'
import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
import { cacheGetters } from "@/store/cache.module"
import { dcaObj }       from '@/mixins/dca_obj.js'

export default {
    name: 'daycard',
    props: ['toast', 'contextStyle'],
    mixins: [ dcaObj ],
    components: { PageHeader, AutocompleteMulti, DatePicker },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            loadingData: true,
            //autoComplete: { managers: [], chiefs: [], equipments: [], weather: [], tasktypes: [] },
            autoCompleteSource: { managers: [], chiefs: [], equipments: [], weather: [], tasktypes: [] },
            modal: {dca_time_start: false, dca_date_end: false },
            prefetchAc: true,
            modify: false,
        }
    },
    computed: { },
    beforeMount: function(){
        this.objDayCard.dca_date = this.$date().format('YYYY-MM-DD')
        if( ( this.$route.params.dca_id ) && this.$route.params.dca_id > 0 ){
            this.prefetchAc = false
        }
    },
    mounted:function (){
        if( ( this.$route.params.dca_id ) && this.$route.params.dca_id > 0 ){
            this.modify = true
            this.objDayCard.dca_id = this.$route.params.dca_id
            this.prefetchAc = true
            this.getForm()
        } else {
            this.loadingData = false
        }
    },
    methods: {
        async getForm(){
            await this.getDayCardShow('?include=equipments,managers,tasktypes,chiefs,weather')
            this.loadingData = false
            
        },
        saveForm () {
            if( this.objDayCard.dca_id > 0 ){
                this.putDayCardStore()
            } else {
                this.postDayCardStore()
            }
        },
        async closeForm(){
            this.objDayCard.dca_sta_id = 'GEN03'
            await this.putDayCardStore()
            this.$router.push({ path: '/daycard' })
        },
        getCacheType: cacheGetters.getTypes,
    }
}
</script>