<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle"></PageHeader>

    <v-card class="ma-4 rounded-lg">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" 
        :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-2">
                    <v-btn color="success" depressed rounded class="white--text" to="/types/0">
                        <v-icon left dark>mdi-pencil-plus-outline</v-icon>
                        <span class="hidden-sm-and-down">Créer</span>
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.typ_id" style="cursor:pointer;">
            <td class="pt-2 text-xs-left">
                <strong flat color="blue" class="ma-0 subheading text-uppercase blue--text">{{ item.typ_id }}</strong>
                &nbsp;<span flat color="blue" class="ma-0 subheading text-capitalize blue--text"></span>
            </td>
            <td class="text-xs-left font-weight-bold">{{ item.typ_name }}</td>
            <td class="text-xs-left">
                <v-chip :active="true" outlined small label :color="item.typ_color" >{{ item.typ_entity }}</v-chip>
            </td>
            <td class="text-xs-left">
                <v-chip :active="true" small dark :color="(item.typ_visibility) ? 'green' : 'greu'" >
                    {{ (item.typ_visibility) ? 'ACTIF' : 'INACTIF' }}
                </v-chip>
            </td>
            <td class="text-right">
                <span class="caption text--disabled font-italic">Créé le </span>
                <span class="caption text--primary font-italic">{{ $date(item.typ_created_at).format('DD MMMM YYYY') }}</span>
                <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/types/' + item.typ_id + ''">
                    <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
                </v-btn>
            </td>
        </tr>
        </tbody>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'types',
    props: ['toast', 'contextStyle'],
    components: {
        PageHeader
    },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Id', align: 'left', value: 'typ_id'},
            { text: 'Nom', align: 'left', value: 'typ_name'},
            { text: 'Type', value: 'typ_entity' },
            { text: 'Statut', value: 'typ_visibility' },
            { text: 'Modification', align: 'right', value: 'typ_updated_at' }
            ],
            items: [],
            enableAdd: true,
            modalEvent : false
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('type') || ''
        this.getTypes()
    },
    methods: {
        getTypes(){
            this.$http.get( '/types/?per_page=-1' ).then( (response) => {
                this.items = response.data.data
            })  
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('type', this.search)
        },
    }
}
</script>