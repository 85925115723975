<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass">
            <v-icon large :color="contextStyle.titleColor" :class="contextStyle.titleIcon">{{ contextStyle.icon }}</v-icon>
            <span>{{ contextStyle.title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>

    <v-card class="ma-4 rounded-lg">
    <v-data-table v-bind:headers="headers" :items="items" item-key="id" :search="search"
        items-per-page-text="Lignes par page" hide-default-header
        :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-1">
                    <v-btn to="/users/0" color="success" rounded class="white--text">
                        <v-icon left dark>mdi-plus-circle</v-icon>
                        <span class="hidden-sm-and-down">Nouveau</span>
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.id" style="cursor:pointer;">
            <td class="pt-2 text-xs-left">
                <strong flat color="blue" class="ma-0 subheading text-uppercase blue--text">{{ item.lastName }}</strong>
                &nbsp;<span flat color="blue" class="ma-0 subheading text-capitalize blue--text">{{ item.firstName }}</span>
                <br />
                <span class="ma-0 pl-0">
                    <v-icon small>mdi-account</v-icon>
                    <span class="grey--text">{{ item.username }}</span>
                    &nbsp;<v-icon small>mdi-email-outline</v-icon>
                    <span class="grey--text">{{ item.email }}</span>
                </span>
            </td>
            <td class="text-xs-left"></td>
            <td class="text-xs-left">
                <v-chip :active="true" outlined small label color="primary" >{{ item.role }}</v-chip>
            </td>
            <td class="text-right">
                <v-icon left class="mr-2">mdi-chart-histogram</v-icon>
                <span class="grey--text font-italic">{{ $date(item.updated_at).format('DD MMMM YYYY') }}</span>
                <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/users/' + item.id + ''">
                    <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
                </v-btn>
            </td>
        </tr>
        </tbody>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
    <v-dialog v-model="modalState" max-width="1000" @keydown.esc="modalState = false" :persistent="false">
        <v-card style="z-index:1100;">
        <v-toolbar color="primary" dark class="elevation-2" height="50px">
            <v-icon class="mr-3">mdi-chevron-down</v-icon>
            <v-toolbar-title>Erreur de log</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="modalState = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text >
            {{ errorLog }}
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>

export default {
    name: 'user',
    props: ['contextStyle'],
    data () {
        return {
            max25chars: (v) => v.length <= 25 || 'Trop de charactères',
            tmp: '',
            search: '',
            modalState: false,
            errorLog: 'Aucune erreur',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Nom', align: 'left', value: 'lastName'},
            { text: 'Mail', align: 'left', value: 'email'},
            { text: 'Role', value: 'role' },
            { text: 'Modification', value: 'updatedAt' }
            ],
            items: [],
            userRole: {
                'BASIC_USER'      : 'Utilisateur (aucun droit)' ,
                'ADMIN_USER'      : 'Administrateur' ,
                'SUPER_USER'      : 'Superviseur' ,
                'EXTERN_USER'     : 'Utilisateur interne' ,
                'PARTNER_USER'    : 'Utilisateur partenaire' ,
            },
        }
    },
    mounted: function (){
        this.$http.get( '/users/?per_page=false' ).then( (response) => {
            this.items = response.data.data
        })
    },
    methods: {
        goRoute(id){
            if( this.$user.usrGetters.getScopeRight('users', 'read') ){
                this.$router.push({ path: '/users/' + id })
            } else {
                this.$root.$toast({ text: 'Droits insuffisant pour modifier une fiche' })
            }
        },
        getErrorLog(){
            this.$http.get( '/errorlog' ).then( (response) => {
                this.errorLog = response.data
                this.modalState = true
            })
        }
    }
}
</script>

<style lang="css">

</style>