import Vue from "vue";
import Storages      from 'js-storage'

export const cacheData = Vue.observable({
  user      : {},
  cacheInfo : {},
  states    : {},
  types     : {},
  usrFilter : {},
  searchBar : {},
  bookmark  : {},

  taskType  : [],
  zones     : [],
  session   : [],
  
  areas     : [],
  subareas  : [],

})

export const cacheGetters = {
  getStates: (group, id) => {
    if( group !== undefined && cacheData.states[group] !== undefined ){
      if( id !== undefined ){
        return ( cacheData.states[group][id] !== undefined ) ? cacheData.states[group][id] : { typ_label: 'Non définit' };
      } else {
        let stateType = []
        for( let prop in cacheData.states[group] ){
          stateType.push( cacheData.states[group][prop] )
        }
        return stateType
      }
    } else {
      return cacheData.states
    }
  },
  getTypes: (group, id) => {
    if( group !== undefined && cacheData.types[group] !== undefined ){
      if( id !== undefined ){
        return ( cacheData.types[group][id] !== undefined ) ? cacheData.types[group][id] : { typ_name: 'Non définit', typ_tag: 'ND' };
      } else {
        let tabType = []
        for( let prop in cacheData.types[group] ){
          tabType.push( cacheData.types[group][prop] )
        }
        return tabType
        //return cacheData.types[group]
      }
    } else if( group !== undefined ){
      let tabType = []  
      return tabType
    } else {
      return cacheData.types
    }
  },
  getTypesByTag: (group, tag) => {
    let tabType = []
    if( group.length ){
      for( let prop in cacheData.types[group] ){
        if( cacheData.types[group][prop].typ_tag == tag ){
          tabType.push( prop )
        }
      }
    } else {
      for( let group in cacheData.types ){
        for( let prop in cacheData.types[group] ){
          if( cacheData.types[group][prop].typ_tag == tag ){
            tabType.push( prop )
          }
        }
      }
    }
    return tabType
  },
  getTypesByGroup: (group) => {
    let tabType = []
    let groupTab = group.split(",")
    groupTab.array.forEach(element => {
      for( let prop in cacheData.types[element] ){
          tabType.push( cacheData.types[element][prop] )
      }      
    })
    return tabType
  },
  getUser: () => {
    return cacheData.user
  },
  getCacheInfo: () => {
    return cacheData.cacheInfo
  },
  getUserRole: () => {
    return cacheData.user.role
  },
  getFilter: () => {
    // default value
    cacheData.usrFilter = ( !cacheData.usrFilter ) ? {} : cacheData.usrFilter
    if( !cacheData.usrFilter.tsk_sta_id ){
      cacheData.usrFilter.tsk_sta_id = ['TSK04']
      cacheData.usrFilter.tsk_typ_id = []
    }
    if( !cacheData.usrFilter.eve_sta_id ){
      cacheData.usrFilter.eve_sta_id = ['TSK01']
    }
    return cacheData.usrFilter
  },
  getFilterUrlForm: (prefix) => {
    prefix     = prefix || 'tsk'
    let filter = cacheGetters.getFilter()
    let form   = {}
    for( var prop in filter ){
      if( filter[prop] !== null && filter[prop] !== undefined && filter[prop].length > 0 && ( prop.substring(0, 3) == prefix || prop.substring(4, 7) == prefix ) ){
        if( prop.slice(-4) == '_arr' ){
          form[prop.slice(0, -4)] = filter[prop]
        } else {
          form[prop] = "" + filter[prop]
        }
      }
    }
    return form
  },
  getFilterUrl: (prefix) => {
    prefix     = prefix || 'tsk'
    let filter = cacheGetters.getFilter()
    let url    = ''
    for( var prop in filter ){
      if( filter[prop] !== null && filter[prop] !== undefined && filter[prop].length > 0 && ( prop.substring(0, 3) == prefix || prop.substring(4, 7) == prefix ) ){
        // must be an array
        if( prop.slice(-4) == '_arr' ){
          filter[prop].forEach( (item) =>{
            url += (item && url.length && item.length) ? '&' : ''
            url += (item && item.length) ? prop.slice(0, -4) + '[]=' + item : ''
          })
        } else {
          url += (url.length) ? '&' : ''
          url += prop + '=' + filter[prop]
        }
      }
    }
    return url
  },
  getFiltrerFromStorage(){
    if( Storages.localStorage.isSet( Vue.prototype.$APP_COD + "_cache") ){
      let cache = Storages.localStorage.get( Vue.prototype.$APP_COD + "_cache")
      return cache.usrFilter || {}
    } else {
      return {}
    }
  },
  getSearchBar: () => {
    return cacheData.searchBar
  },
  getSearchBarItem: (item) => {
    if( cacheData.searchBar !== undefined ){
      return cacheData.searchBar[item]
    } else {
      return ''
    }
  },
  getBookmark: () => {
    return cacheData.bookmark
  },
  getTaskType: () => {
    return cacheData.taskType
  },
  getZone: () => {
    return cacheData.zones
  },
  getSession: () => {
    return cacheData.session
  },
  getArea: (zoneId) => {
    if( zoneId ){
      return cacheData.areas.filter( (item) => {
        return (item.are_zon_id == zoneId) ? true : false
      })
    } else {
      return cacheData.areas
    }
  },
  getSubarea: () => {
    return cacheData.subareas
  },
}

export const cacheActions = {
  initCacheFromStorage(){
    new Promise((resolve) => {
      if( Storages.localStorage.isSet( Vue.prototype.$APP_COD + "_cache") ){
        let cache = Storages.localStorage.get( Vue.prototype.$APP_COD + "_cache")
        cacheMutations.setStates( cache.states )
        cacheMutations.setTypes( cache.types )
        cacheMutations.setUser( cache.user || {} )
        cacheMutations.setFilter( cache.usrFilter || {} )
        cacheMutations.setBookmark( cache.bookmark || {} )
        cacheMutations.setSession( cache.session || [] )
        cacheMutations.setCacheInfo( cache.cacheInfo || {} )
        cacheMutations.setZone( cache.zones || [] )
        cacheMutations.setArea( cache.areas || [] )
        cacheMutations.setTaskType( cache.taskType || [] )
      }
      resolve('resolved')
    })
  },
  async storeCacheToStorage(){
    new Promise((resolve) => {
      Storages.localStorage.set( Vue.prototype.$APP_COD + "_cache", cacheData)
      resolve('resolved')
    })
  }
}

export const cacheMutations = {
  setStates( states ) {
    cacheData.states = states;
  },
  setTypes( types ) {
    cacheData.types = types;
  },
  setUser( user ) {
    cacheData.user = user;
  },
  setCacheInfo( cacheInfo ) {
    cacheData.cacheInfo = cacheInfo;
  },
  setFilter( usrFilter ) {
    cacheData.usrFilter = usrFilter;
  },
  setSearchBar( searchBar ) {
    cacheData.searchBar = searchBar;
  },
  setBookmark( bookmark ) {
    cacheData.bookmark = bookmark;
  },
  setSearchBarItem( item, value ) {
    cacheData.searchBar[item] = value;
  },
  setTaskType( taskType ) {
    cacheData.taskType = taskType;
  },
  setZone( zones ) {
    cacheData.zones = zones;
  },
  setSession( session ) {
    cacheData.session = session;
  },
  setArea( areas ) {
    cacheData.areas = areas;
  },
  setSubarea( subareas ) {
    cacheData.subareas = subareas;
  },

}