import Vue           from 'vue'
import App           from '@/App.vue'
import router        from '@/services/router'
import vuetify       from '@/plugins/vuetify';

import '@/plugins/download'
import '@/plugins/dayjs'
import '@/plugins/apexchart'
import '@/plugins/api'
import '@/plugins/build'
import '@/plugins/storage'
import '@/plugins/user'

// Event bus
Vue.prototype.$eventBus = new Vue({})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
