var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"ma-4 rounded-lg elevation-2",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"sta_id","search":_vm.search,"items-per-page-text":"Lignes par page","items-per-page-options":_vm.pagination.rowsPerItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"rounded-lg ma-0 pa-0",staticStyle:{"background-color":"#FAFAFA"}},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"6","md":"6"}},[_c('v-text-field',{staticClass:"pa-2 rounded-xl",attrs:{"append-icon":"mdi-magnify","label":"Recherche","outlined":"","dense":"","clearable":"","single-line":"","hide-details":""},on:{"blur":function($event){return _vm.saveSearchToCache()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right pa-2",attrs:{"cols":"6","md":"6"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"success","depressed":"","rounded":"","to":"/equipments/0"}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-pencil-plus-outline")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Créer")])],1)],1)],1)]},proxy:true},{key:"item.equ_sta_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","label":"","color":_vm.getCacheState('GEN', item.equ_sta_id).sta_color}},[_vm._v(" "+_vm._s(_vm.getCacheState('GEN', item.equ_sta_id).sta_label)+" ")])]}},{key:"item.equ_id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2 elevation-1",attrs:{"outlined":"","small":"","color":"grey","href":'/#/equipments/' + item.equ_id + ''}},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("Ouvrir ")],1)]}},{key:"pageText",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
return [_vm._v(" De "+_vm._s(pageStart)+" à "+_vm._s(pageStop)+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }