<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle"></PageHeader>

    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" v-bind:items="items" v-bind:search="search"
        :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pagination.pageCount = $event"
        footer-props.items-per-page-text="Lignes par page" hide-default-footer>
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-2">
                    <v-btn color="success" depressed rounded class="white--text" to="/areas/0">
                        <v-icon left dark>mdi-pencil-plus-outline</v-icon>
                        <span class="hidden-sm-and-down">Créer</span>
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.are_name`]="{ item }">
            <v-chip label outlined :color="getCacheType('SIT', item.are_typ_id).typ_color">
                {{ item.are_name }}
            </v-chip>
        </template>
        <template v-slot:[`item.are_sta_id`]="{ item }">
            <v-chip label outlined :color="getCacheState('GEN', item.are_sta_id).typ_color">
                {{ getCacheState('GEN', item.are_sta_id).sta_label }}
            </v-chip>
        </template>
        <template v-slot:[`item.are_updated_at`]="{ item }">
        {{ $date(item.are_updated_at).format('DD MMMM YYYY') }}
        </template>
        <template v-slot:[`item.are_color`]="{ item }">
            <v-btn small :color="item.are_color"></v-btn>
        </template>
        <template v-slot:[`item.are_id`]="{ item }">
            <v-btn color="grey" outlined :to="'/areas/' + item.are_id">Modifier</v-btn>
            <v-btn class="ml-2" color="red" outlined @click="deleteItem(item.are_id)">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
        <template slot="no-data">
            <v-alert :value="true" color="error" class="mt-2" icon="mdi-alert">Aucune données</v-alert>
        </template>
    </v-data-table>
    </v-card>
    <v-layout wrap>
        <v-flex xs6 class="text-left pl-8">
            <span>{{pagination.totalItems}} résultats</span>
        </v-flex>
        <v-flex xs6 class="text-right pr-0">
            <v-pagination v-model="pagination.page" :length="pagination.pageCount" :total-visible="7" ></v-pagination> <!-- :length="pages" @input="handlePage" -->
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'layers',
    props: ['contextStyle'],
    components: { PageHeader },
    data () {
        return {
            max25chars: (v) => v.length <= 25 || 'Trop de charactères',
            tmp: '',
            search: '',
            pagination: { page: 1, totalItems: 0, itemsPerPage: 12, totalPage: 1, limit: 0, pageCount: 0 },
            headers: [
            { text: 'Nom',             align: 'left',   value: 'are_name'},
            { text: 'Etat',            align: 'left',   value: 'are_sta_id'},
            { text: 'Couleur',         align: 'left',   value: 'are_color'},
            { text: 'Modification',    align: 'right',   value: 'are_updated_at'},
            { text: 'Action',          align: 'right',   value: 'are_id'},
            ],
            items: [],
            cacheType: cacheGetters.getTypes(),
            cacheState: cacheGetters.getStates(),
            enableAdd: true,
            modalEvent : false
        }
    },
    computed: {
        pages () {
            if (this.pagination.totalItems == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
        },
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('layers')
        this.getAreas()
    },
    methods: {
        getAreas(){
            this.items = []
            var searchCriteria = '?page='+ this.pagination.page +'&per_page=false' // + this.pagination.itemsPerPage
            searchCriteria    += '&extended=true' //'&order_by=trs_name&order_direction=desc'
            this.$http.get( '/areas/' + searchCriteria ).then( (response) => {
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.items = response.data.data
                }
            })
        },
        saveVersion(){
                this.getEvent()
                this.enableAdd = true
        },
        downloadSite(){
            this.$root.$toast({ color: 'success', text: 'Fonction non disponible !' })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            //this.pagination.page  = (index - 1)
            this.getAreas()
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('layers', this.search)
        },
        deleteItem(id){
            this.$root.$confirm('Supprimer la couche (' + id + ')', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/areas/' + id).then( () => {
                        this.$root.$toast({ color: 'success', text: 'Action enregistrée !' })
                        this.getAreas()
                        this.switchModal('-', 0, true)
                    })
                }
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    }
}
</script>

<style lang="css">

</style>