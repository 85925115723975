<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass" color="barrtextinfo">
            <v-breadcrumbs large divider=">" :items="contextStyle.breadcrumbs" :class="'pl-0'">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">{{ item.text }}</v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
    <!-- Formulaire -->
    <v-card fluid class="ma-4 pa-5 elevation-2 rounded-lg">
        <v-layout row wrap>
        <v-flex xs4 class="pa-2">
            <v-text-field label="Nom"    v-model="item.lastName" ></v-text-field>
            <v-text-field label="Prénom" v-model="item.firstName"></v-text-field>
            <v-text-field label="Login"  v-model="item.username"></v-text-field>
            <v-text-field label="Mot de passe" type="password" v-model="item.password" loading>
                <template v-slot:progress>
                    <v-progress-linear :value="progress" :color="color" height="3" absolute></v-progress-linear>
                </template>
            </v-text-field>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-text-field label="Tel" v-model="item.phone"></v-text-field>
            <v-text-field label="Mobile" v-model="item.mobile"></v-text-field>
            <v-text-field label="Courriel" v-model="item.email"></v-text-field>
            <v-select :items="roleList" item-value="value" v-model="item.role" label="Role" single-line >
            </v-select>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-container fluid class="text-right">
            <v-chip outlined color="blue">
                <v-icon left>mdi-information-outline</v-icon>Modification: {{ $date(item.updatedAt).format('DD MMMM YYYY') }}
            </v-chip>
            </v-container>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-select
                item-text="zon_name" item-value="zon_id"
                :items="getCacheZone()" v-model="item.zoneId" label="Zone par défaut" >
                <template v-slot:prepend><v-icon>mdi-layers-search</v-icon></template>
            </v-select>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <AutocompleteMulti v-if="!loading.getItem"
                :title="'Zones autorisées'"
                :listObject.sync="item.userzone" :listParent.sync="listZones" 
                :fields="{ 
                    index: 'zon_id', text:'zon_name', autofocus: true, outlined: true, dense: true,
                    search:'zon_name', multiSelect:true, prefetch: false, params:''
            }"></AutocompleteMulti>
        </v-flex>
        </v-layout>
        <v-card-actions>
            <v-btn color="red" v-if="item.uid" @click="deleteUser(item)"><v-icon title="Supprimer">mdi-delete</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" :loading="saveProgress" @click.native="saveUser()" :disabled="disabledSave">Enregistrer</v-btn>
            <v-btn color="primmary" href="/#/users/">Retour</v-btn>
        </v-card-actions>
    </v-card>
</v-container>
</template>

<script>
import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
import { cacheGetters } from "@/store/cache.module"

export default {
    name: 'user_card',
    props: ['contextStyle'],
    components: { AutocompleteMulti },
    data: function () {
        return {
            loading: { getItem: false },
            saveProgress: false,         // Enregistrement en cours...
            roleList: [
                {'value': 'BASIC_USER',      'text': 'Utilisateur (aucun droit)' },
                {'value': 'ADMIN_USER',      'text': 'Administrateur' },
                {'value': 'SUPER_USER',      'text': 'Superviseur' },
                //{'value': 'WORK_SUPERVISOR', 'text': 'Superviseur' },
                {'value': 'TEAM_LEADER',     'text': 'Chef d\'equipe' },
                {'value': 'AGENT',           'text': 'Agent' },
                {'value': 'DRIVER',          'text': 'Chauffeur' },

                {'value': 'CUS_USER',        'text': 'Client - Utilisateur' },
                {'value': 'CUS_WKSUP_USER',  'text': 'Client - Controleur de travaux' },
                //{'value': 'EXTERN_USER',     'text': 'Utilisateur interne' },
                //{'value': 'PARTNER_USER',    'text': 'Utilisateur partenaire' },
            ],
            item:{
              id: 0, uid:'', username: '', password: '', zoneId: 0,
              lastName: '', firstName: '',
              phone: '', mobile: '', email: '',
              created_at: '2000-01-01', updated_at: '',
              isActive: 1, sta_id_type: 'USR01', role: 'BASIC_USER', userzone: []
            },
            listZones: this.getCacheZone()
        }
    },
    computed: {
        progress () {
            return Math.min(100, this.item.password.length * 12.5)
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
        },
        disabledSave(){
            if(this.saveProgress){
                return true
            }
            if( this.item.email.length > 2 ){
                return false
            } else {
                return true
            }
        }
    },
    mounted:function (){
        if( parseInt( this.$route.params.id ) > 0 && this.$route.params.id !== undefined ){
            this.loading.getItem = true
            this.$http.get( '/users/' + this.$route.params.id + '/?include=userzone' ).then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.item[prop] !== undefined ){
                        this.item[prop] = response.data.data[prop]
                        if( prop == 'userzone' ){
                            this.item[prop] = response.data.data[prop].map(a => a.zon_id)
                        }
                    }
                }
                this.loading.getItem = false
            })
            .catch( (error) => {
                this.$root.$toast({ color: 'warning', text: error })
            })
        }
    },
    methods: {
        saveUser () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( parseInt( this.$route.params.id ) > 0 ){
                this.$http.put( '/users/' + this.$route.params.id, this.item )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/users' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                })
            } else {
                this.$http.post( '/users/', this.item )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/users' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                })
            }
        },
        deleteUser(item){
            this.$root.$confirm('Supprimer l\'utilisateur (' + item.id + ')', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.saveProgress = true
                    this.$http.delete( '/users/' + item.id).then( () => {
                        this.$root.$toast({ color: 'success', text: 'Utilisateur supprimé !' })
                        this.saveProgress = false
                        this.$router.push({ path: '/users' })
                    }).catch( (error) => {
                        this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                        this.saveProgress = false
                    })
                }
            })
        },
        getCacheSession : cacheGetters.getSession,
        getCacheZone: cacheGetters.getZone,
    }
}
</script>