const ChMain        = () => import('@/layouts/Main.vue') 
const ChDasboard    = () => import('@/pages/Dashboard.vue')
const ChTasks       = () => import('@/pages/Tasks.vue')
const ChPlanning    = () => import('@/pages/Planning.vue')
const ChMap         = () => import('@/pages/Map.vue')
const ChSlider      = () => import('@/components/tasks/TaskSlider.vue')
const ChEditTask    = () => import(/* webpackChunkName: "build-glob" */ '@/components/tasks/TaskForm.vue')
const ChMassCreateTask = () => import(/* webpackChunkName: "build-glob" */ '@/components/tasks/TaskMassCreate.vue')
const ChMassEditTask = () => import(/* webpackChunkName: "build-glob" */ '@/components/tasks/TaskMassEdit.vue')
const ChPreViewSar  = () => import(/* webpackChunkName: "build-glob" */ '@/components/subarea/SubareaView.vue')
const ChPreEditSar  = () => import(/* webpackChunkName: "build-glob" */ '@/components/subarea/SubareaForm.vue')
//const ChViewTask    = () => import(/* webpackChunkName: "build-glob" */ '@/components/tasks/TaskView.vue')

export default {
menu : [
    { 'href': '/dashboard',       'scope':'Generic', 'right': 'index', 'title': 'Tableau de bord', 'icon': 'mdi-view-dashboard' },
    { 'href': '/tasks',           'scope':'Generic', 'right': 'index', 'title': 'Liste des tâches', 'icon': 'mdi-traffic-cone' },
    { 'href': '/quotations',      'scope':'Generic', 'right': 'index', 'title': 'Liste des ponctuels', 'icon': 'mdi-receipt-text-check-outline' },
    { 'href': '/planning',        'scope':'Generic', 'right': 'index', 'title': 'Calendrier', 'icon': 'mdi-calendar-check' },
    { 'href': '/map',             'scope':'Map',     'right': 'index', 'title': 'Carte', 'icon': 'mdi-map-outline' },
    { 'href': '/daycard/current', 'scope':'Daycard', 'right': 'index', 'title': 'Fiche jour', 'icon': 'mdi-note-multiple-outline' },
    { divider: true },
    { 'header': 'Reporting', 'scope':'Report', 'right': 'index' },
    { 'href': '/reports',    'scope':'Report', 'right': 'index', 'title': 'Export', 'icon': 'mdi-file-chart' },
    { divider: true },
    { 'header': 'Paramètres',    'scope':'Setting',   'right': 'update', 'title': 'Paramètres', 'icon': 'mdi-cogs', items: [
        { 'href': '/zones',      'scope':'Zone',      'right': 'update', 'title': 'Gestion des zones', 'icon': 'mdi-file-tree-outline' },
        { 'href': '/areas',      'scope':'Area',      'right': 'update', 'title': 'Gestion des surfaces', 'icon': 'mdi-layers-outline' },
        { 'href': '/equipments', 'scope':'Equipment', 'right': 'update', 'title': 'Materiel', 'icon': 'mdi-mower-bag' },
        { 'href': '/tasktypes',  'scope':'TaskType',  'right': 'update', 'title': 'Type de taches', 'icon': 'mdi-file-tree' },
        { 'href': '/states',     'scope':'State',     'right': 'update', 'title': 'Gestion états', 'icon': 'mdi-tag-multiple' },
        { 'href': '/types',      'scope':'Type',      'right': 'update', 'title': 'Gestion des types', 'icon': 'mdi-format-list-bulleted-type' },
        { 'href': '/contacts',   'scope':'Contact',   'right': 'update', 'title': 'Gestion des contacts', 'icon': 'mdi-account-box-multiple' },
    ] },
    { divider: true },
    { 'header': 'Systeme', 'role': ['ADMIN_USER', 'SUPER_USER' ] },
    { 'href': '/users', 'title': 'Utilisateurs', 'icon': 'mdi-account', 'role': ['ADMIN_USER', 'SUPER_USER' ] },
    //{ 'href': '/importdata',  'title': 'Import / synchro',  'icon': 'mdi-file-upload', 'role': ['ADMIN_USER', 'SUPER_USER'] },
    //{ 'href': '/jobs',  'title': 'Taches de fond', 'icon': 'mdi-progress-wrench', 'role': ['ADMIN_USER', 'SUPER_USER'] },
],
routes : [
    { name:'login', path: '/login',   component: require('./layouts/Login.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'logout', path: '/logout',   component: require('./layouts/Logout.vue').default },
    { name: '404', path: '*', component: require('./layouts/404.vue').default },
    { name: '401', path: '/401', component: require('./layouts/401.vue').default },
    { path: '/', component: ChMain,
        children: [
            { name: 'home', path: '/', redirect: '/dashboard' },
            { name: 'dashboard', path: '/dashboard', 
                components: { default: ChDasboard, modal: ChSlider },
                children: [
                  //{ name: 'dash_slide_prev', path: 'sheet/:tsk_id', meta: { component: 'taskView' }, components: { content: ChEditCard } },
                  //{ name: 'dash_slide_edit', path: 'sheet/:tsk_id/modifier', components: { content: ChEditCard } },
                  //{ name: 'dash_slide_view', path: 'sheet/:tsk_id/action/:act_id', components: { content: ChViewAction } }
                ]
            },
            { name: 'tasks', path: '/tasks', 
                components: { default: ChTasks, modal: ChSlider },
                children: [
                    { name: 'tsk_slide_prev', path: 'subarea/:sar_id', meta: { component: 'taskView' }, components: { content: ChPreViewSar } },
                    { name: 'tsk_slide_view', path: 'subarea/:sar_id/task/:tsk_id', components: { content: ChEditTask } }
                  //{ name: 'map_slide_prev', path: 'sheet/:tsk_id', meta: { component: 'taskView' }, components: { content: ChEditCard } },
                  //{ name: 'map_slide_edit', path: 'sheet/:tsk_id/modifier', components: { content: ChEditCard } },
                  //{ name: 'map_slide_add',  path: 'sheet/:tsk_id/addtask/:tre_id', meta: { component: 'taskView' }, components: { content: ChEditCard } },
                  
                ]
            },
            { name: 'planning', path: '/planning', 
                components: { default: ChPlanning, modal: ChSlider },
                children: [
                    { name: 'cal_slide_prev', path: 'subarea/:sar_id', meta: { component: 'taskView' }, components: { content: ChPreViewSar } },
                    { name: 'cal_slide_view', path: 'subarea/:sar_id/task/:tsk_id', components: { content: ChEditTask } }
                  
                ]
            },
            { name: 'map', path: '/map', 
                components: { default: ChMap, modal: ChSlider },
                children: [
                    { name: 'map_slide_creat',path: 'taskmasscreate/:tsk_id', meta: { component: 'taskMassCreate' }, components: { content: ChMassCreateTask } },
                    { name: 'map_slide_edit', path: 'taskmassedit/:tsk_id', meta: { component: 'taskMassEdit' }, components: { content: ChMassEditTask } },
                    { name: 'map_slide_prev', path: 'taskform/:tsk_id', meta: { component: 'taskView' }, components: { content: ChEditTask } },
                    { name: 'map_slide_edit_sar',  path: 'subarea/:tsk_id', meta: { component: 'SubareaForm' }, components: { content: ChPreEditSar } },
                    { name: 'map_slide_edit_task', path: 'taskform/:tsk_id/modifier', components: { content: ChEditTask } },
                  //{ name: 'map_slide_add',  path: 'sheet/:tsk_id/addtask/:tre_id', meta: { component: 'taskView' }, components: { content: ChEditCard } },
                  
                ]
            },
            { name: 'geoalert',      path: '/geoalert',             component: require('./pages/Geoalert.vue').default },

            { name: 'quotation',     path: '/quotations',             component: require('./pages/Quotation.vue').default },
            { name: 'quotationOpen', path: '/quotations/:eve_id',     component: require('./pages/Quotation.vue').default },

            { name: 'reports',       path: '/reports',             component: require('./pages/Reports.vue').default },
            
            { name: 'users',         path: '/users',             component: require('./pages/Users.vue').default },
            { name: 'useredit',      path: '/users/:id',         component: require('./pages/User_edit.vue').default },
            
            { name: 'zones',         path: '/zones',             component: require('./pages/Zones.vue').default },
            { name: 'zonesedit',     path: '/zones/:zon_id',     component: require('./components/zone/ZonesForm.vue').default },
            { name: 'areas',         path: '/areas',             component: require('./pages/Areas.vue').default },
            { name: 'areasedit',     path: '/areas/:are_id',     component: require('./components/areas/AreaForm.vue').default },
            { name: 'contacts',      path: '/contacts',          component: require('./pages/Contacts.vue').default },
            { name: 'contacts_view', path: '/contacts/:ctt_id',  component: require('./pages/Contacts_edit.vue').default },
            { name: 'daycards',      path: '/daycard',           component: require('./pages/DayCards.vue').default },
            { name: 'daycardedit',   path: '/daycard/:dca_id',   component: require('./components/daycard/DayCardForm.vue').default },
            { name: 'equipments',    path: '/equipments',        component: require('./pages/Equipments.vue').default },
            { name: 'equipmendit',   path: '/equipments/:equ_id',component: require('./components/equipment/EquipmentForm.vue').default },
            { name: 'tasktypes',     path: '/tasktypes',         component: require('./pages/TaskTypes.vue').default },
            { name: 'tasktypesedit', path: '/tasktypes/:tty_id', component: require('./components/tasktypes/TaskTypesForm.vue').default },
            { name: 'states',        path: '/states',            component: require('./pages/States.vue').default },
            { name: 'statesedit',    path: '/states/:sta_id',    component: require('./components/states/StatesForm.vue').default },
            { name: 'types',         path: '/types',             component: require('./pages/Types.vue').default },
            { name: 'typesedit',     path: '/types/:typ_id',     component: require('./components/types/TypeForm.vue').default },

            { name: 'forbidden',    path: '/forbidden', component: require('./layouts/403.vue').default },
            
        ]
    }
]


}